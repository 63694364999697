<template>
	<div>
		<b-button
			:id="refButton.id"
			:class="{ 'btn-icon rounded-circle': refButton.onlyIcon }"
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			:variant="refButton.variant"
			:size="refButton.size"
			:block="refButton.block"
			@click="getFiles"
		>
			<feather-icon
				:icon="refButton.icon"
				:class="{ 'mr-1': !refButton.onlyIcon }"
				size="1x"
			/>
			<span v-if="!refButton.onlyIcon">{{ refButton.text }}</span>
		</b-button>

		<b-modal
			ref="modal"
			modal-class="modal-primary"
			:title="refModal.title"
			:size="refModal.size"
			no-close-on-backdrop
			hide-header-close
			no-close-on-esc
			centered
		>
			<div v-if="documents.length > 0">
				<label class="description-modal mt-1 mb-1">Lista de documentos requeridos</label>
				<ul>
					<li
						v-for="(document, index) in documents"
						:key="index"
						class="content-modal"
					>{{ document.name }}</li>
				</ul>
			</div>

			<template v-if="!isDisabled && canUpload">
				<hr />

				<div class="my-1">
					<vue-dropzone
						ref="dropzone"
						id="dropzone"
						:options="dropzoneOptions"
						:headers="dropzoneHeaders"
						:useCustomSlot="true"
						@vdropzone-sending-multiple="vsending"
						@vdropzone-success-multiple="vsuccess"
						@vdropzone-error="verror"
						@vdropzone-removed-file="vremovedfile"
					>
						<div class="dropzone-custom-content">
							<h4 class="dropzone-custom-title">
								<strong>¡Arrastre o haga click aquí para cargar contenido!</strong>
							</h4>
							<div
								class="subtitle"
								v-if="!anyFile"
							>archivos permitidos PDF, EXCEL, DOC, DOCX, PPT, PPTX, ZIP, RAR</div>
							<div class="small text-muted">peso máximo 25MB</div>
						</div>
					</vue-dropzone>
				</div>

				<hr />
			</template>

			<table
				v-if="canWatch"
				class="table table-bordered text-center w-100 mt-2 mb-1"
			>
				<thead>
					<tr>
						<th>#</th>
						<th colspan="2">Archivo</th>
						<th>Fecha de carga</th>
						<th>Opciones</th>
					</tr>
				</thead>

				<tbody>
					<tr v-if="!files.length">
						<td colspan="5">NO HAY NINGÚN ARCHIVO CARGADO.</td>
					</tr>
					<tr
						v-for="(item, index) in files"
						:key="index"
					>
						<td>
							<span class="text-nowrap">{{ index + 1 }}</span>
						</td>
						<td colspan="2">{{ item.original_name }}</td>
						<td>
							<span class="text-nowrap">{{ moment(item.createdAt).format('DD/MM/YYYY HH:mm A') }}</span>
						</td>
						<td>
							<div class="justify-content-center align-items-center">
								<b-button
									:href="item.url"
									target="_blank"
									v-ripple.400="'rgba(40, 199, 111, 0.15)'"
									variant="flat-primary"
									class="btn-icon rounded-circle"
									title="Descargar"
								>
									<feather-icon icon="DownloadIcon" />
								</b-button>

								<b-button
									v-if="!isDisabled && canDestroy"
									v-ripple.400="'rgba(40, 199, 111, 0.15)'"
									variant="flat-danger"
									class="btn-icon rounded-circle"
									title="Eliminar"
									@click="deleteFile(item.id)"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<template #modal-footer="{ cancel }">
				<b-button
					variant="outline-secondary"
					@click="cancel(); reset()"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { FileTypes } from "@core/utils/data"
import { ref, onUnmounted } from "@vue/composition-api"
import uploadStoreModule from "./uploadStoreModule"
import useNotifications from "@notifications"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import { getUserRoles } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import vue2Dropzone from "vue2-dropzone"
import store from "@/store"
import moment from "moment"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

export default {
	components: {
		vueDropzone: vue2Dropzone
	},
	props: {
		entity: {
			type: String,
			required: true
		},
		entityId: Number,
		requestProps: {
			type: Object,
			default: () => ({})
		},
		tenderProps: {
			type: Object,
			default: () => ({})
		},
		competitionProps: {
			type: Object,
			default: () => ({})
		},
		modalProps: {
			type: Object,
			default: () => ({})
		},
		buttonProps: {
			type: Object,
			default: () => ({})
		},
		acceptedFiles: {
			type: String,
			default: ".pdf,.xls,.xlsx, .doc, .docx, .ppt, .pptx, .zip, .rar"
		},
		anyFile: {
			type: Boolean,
			default: false
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		onlyRead: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	setup(props) {
		const UPLOAD_FILE_APP_STORE_MODULE_NAME = "app-upload"

		// REGISTER MODULE
		if (!store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME))
			store.registerModule(UPLOAD_FILE_APP_STORE_MODULE_NAME, uploadStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME))
				store.unregisterModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)
		})

		const { swalNotification } = useNotifications()
		const { isBidder, isManager, isAdmin, isSuperAdmin } = getUserRoles()
		const {
			GENERAL,
			ESPECIFICO,
			VISITA,
			CONSULTA,
			ABSOLUCION,
			SIG_TECNICA,
			ECONOMICA,
			EVIDENCIA
		} = FileTypes

		// REFS
		const refButton = ref({
			id: props.buttonProps.id || "idFile",
			variant: props.buttonProps.variant || "outline-primary",
			block: props.buttonProps.block || false,
			icon: props.buttonProps.icon || "PaperclipIcon",
			size: props.buttonProps.size || "md",
			onlyIcon: props.buttonProps.onlyIcon || false,
			text: props.buttonProps.text || "CARGAR ARCHIVOS"
		})
		const refModal = ref({
			title: props.modalProps.title || "DOCUMENTOS",
			size: props.modalProps.size || "xl"
		})

		const refRequest = ref({
			isGeneral: props.requestProps.isGeneral || false,
			isSpecific: props.requestProps.isSpecific || false
		})

		const refTender = ref({
			isEvidence: props.tenderProps.isEvidence || false
		})

		const refCompetition = ref({
			requestId: props.competitionProps.requestId || null,
			ownerId: props.competitionProps.ownerId || null,
			isSpecific: props.competitionProps.isSpecific || false,
			isVisit: props.competitionProps.isVisit || false,
			isConsultation: props.competitionProps.isConsultation || false,
			isAbsolution: props.competitionProps.isAbsolution || false,
			isEconomic: props.competitionProps.isEconomic || false,
			isSigTechnical: props.competitionProps.isSigTechnical || false
		})

		const refEntity = ref(props.entity)
		const refEntityId = ref(props.entityId)
		const onlyRead = ref(props.onlyRead)

		const modal = ref(null)
		const documents = ref([])
		const files = ref([])
		const payload = ref({
			entity: refEntity.value,
			entity_id: refEntityId.value
		})
		const dropzone = ref(null)
		const dropzoneOptions = ref({})
		const dropzoneHeaders = ref({})

		const canUpload = ref(true)
		const canWatch = ref(true)
		const canDestroy = ref(true)

		const setDropzoneConfig = () => {
			dropzoneHeaders.value = { "Content-Type": "multipart/form-data" }
			dropzoneOptions.value = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("accessToken")}`
				},
				url: `${process.env.VUE_APP_HOST}/api/dashboard/files`,
				method: "post",
				paramName: "files",
				autoDiscover: false,
				maxFiles: 10,
				maxFilesize: 25,
				dictFileTooBig: "No puede exceder el tamaño máximo. Límite 25MB",
				timeout: 180000,
				maxThumbnailFilesize: 10,
				parallelUploads: 10,
				acceptedFiles: props.anyFile ? "" : props.acceptedFiles,
				autoProcessQueue: true,
				thumbnailWidth: 140,
				thumbnailHeight: 140,
				uploadMultiple: true,
				addRemoveLinks: true,
				dictCancelUpload: "Cancelar Carga"
			}
		}

		const fetchFiles = (params) => {
			store
				.dispatch("app-upload/fetchFiles", { payload: params })
				.then((response) => {
					files.value = response.data.files || []
					modal.value.show()
				})
				.catch((error) => {
					swalNotification(error.response.data.message, false)
				})
		}

		const reset = () => {
			payload.value = {}
			canUpload.value = false
			canWatch.value = false
			canDestroy.value = false
		}

		const getRequestGeneralFiles = () => {
			setPermissions()
			payload.value.file_type_id = GENERAL
			fetchFiles(payload.value)
		}

		const getSpecificDocuments = async () => {
			const { data } = await axios.get(`/documents?is_specific=1`)
			documents.value = [...data.documents]
		}

		const getVisitDocuments = async (request_id) => {
			const { data } = await axios.get("/request-visit-documents", {
				params: { request_id }
			})
			documents.value = [...data.documents]
		}

		const getRequestSpecificFiles = () => {
			const uploading = isBidder ? !onlyRead.value : false
			const watching = isBidder ? true : onlyRead.value
			const destroying = isBidder ? !onlyRead.value : false

			setPermissions({ uploading, watching, destroying })
			getSpecificDocuments()

			payload.value.file_type_id = ESPECIFICO
			fetchFiles(payload.value)
		}

		const getCompetitionSpecificFiles = () => {
			const uploading = isBidder ? true : false
			const watching = true
			const destroying = isBidder ? true : false

			setPermissions({ uploading, watching, destroying })
			getSpecificDocuments()

			payload.value.file_type_id = ESPECIFICO
			fetchFiles(payload.value)
		}

		const getCompetitionVisitFiles = async () => {
			const uploading = isBidder ? true : false
			const watching = true
			const destroying = isBidder ? true : false

			setPermissions({ uploading, watching, destroying })
			const { requestId, ownerId } = refCompetition.value
			getVisitDocuments(requestId)

			payload.value.file_type_id = VISITA
			payload.value.owner_id = ownerId
			fetchFiles(payload.value)
		}

		const getCompetitionConsultationFiles = async () => {
			const uploading = isBidder ? true : false
			const watching = true
			const destroying = isBidder ? true : false

			setPermissions({ uploading, watching, destroying })
			const { ownerId } = refCompetition.value

			payload.value.file_type_id = CONSULTA
			payload.value.owner_id = ownerId
			fetchFiles(payload.value)
		}

		const getCompetitionAbsolutionFiles = async () => {
			const uploading = isBidder ? false : true
			const watching = true
			const destroying = isBidder ? false : true

			setPermissions({ uploading, watching, destroying })
			const { ownerId } = refCompetition.value

			payload.value.file_type_id = ABSOLUCION
			payload.value.owner_id = ownerId
			fetchFiles(payload.value)
		}

		const getCompetitionEconomicFiles = async () => {
			const uploading = isBidder ? true : false
			const watching = true
			const destroying = isBidder ? true : false

			setPermissions({ uploading, watching, destroying })
			const { ownerId } = refCompetition.value

			payload.value.file_type_id = ECONOMICA
			payload.value.owner_id = ownerId
			fetchFiles(payload.value)
		}

		const getCompetitionSigTechnicalFiles = async () => {
			const uploading = isBidder ? true : false
			const watching = true
			const destroying = isBidder ? true : false

			setPermissions({ uploading, watching, destroying })
			const { ownerId } = refCompetition.value

			payload.value.file_type_id = SIG_TECNICA
			payload.value.owner_id = ownerId
			fetchFiles(payload.value)
		}

		const getTenderWinnerEvidenceFiles = () => {
			setPermissions()

			payload.value.file_type_id = EVIDENCIA
			fetchFiles(payload.value)
		}

		const setPermissions = ({
			uploading = true,
			watching = true,
			destroying = true
		} = {}) => {
			canUpload.value = uploading
			canWatch.value = watching
			canDestroy.value = destroying
		}

		const entityMap = {
			REQUESTS: [
				{
					condition: refRequest.value.isGeneral,
					action: getRequestGeneralFiles
				},
				{
					condition: refRequest.value.isSpecific,
					action: getRequestSpecificFiles
				}
			],
			COMPETITIONS: [
				{
					condition: refCompetition.value.isSpecific,
					action: getCompetitionSpecificFiles
				},
				{
					condition: refCompetition.value.isVisit,
					action: getCompetitionVisitFiles
				},
				{
					condition: refCompetition.value.isConsultation,
					action: getCompetitionConsultationFiles
				},
				{
					condition: refCompetition.value.isAbsolution,
					action: getCompetitionAbsolutionFiles
				},
				{
					condition: refCompetition.value.isEconomic,
					action: getCompetitionEconomicFiles
				},
				{
					condition: refCompetition.value.isSigTechnical,
					action: getCompetitionSigTechnicalFiles
				}
			],
			TENDERS: [
				{
					condition: refTender.value.isEvidence,
					action: getTenderWinnerEvidenceFiles
				}
			]
		}

		const loadEntityFiles = () => {
			payload.value.entity = refEntity.value
			payload.value.entity_id = refEntityId.value

			const entityConditions = entityMap[refEntity.value] || []
			const action = entityConditions.find((item) => item.condition)?.action
			if (action) action()
		}

		const getFiles = () => {
			reset()
			setDropzoneConfig()
			loadEntityFiles()
		}

		const deleteFile = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar el archivo?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-upload/deleteFile", { id })
						.then((response) => {
							getFiles()
							swalNotification(response.data.message)
						})
						.catch(() => {
							swalNotification("Error al eliminar el archivo", false)
						})
				}
			})
		}

		const vsending = (files, xhr, formData) => {
			const fileData = {
				...payload.value,
				files_count: files.length
			}
			formData.append("fileData", JSON.stringify(fileData))
		}

		const vsuccess = (files, response) => {
			getFiles()

			if (dropzone.value) {
				dropzone.value.removeAllFiles()
			}

			swalNotification(response.message, true, 3500)
		}

		const verror = (files, response, xhr) => {
			getFiles()

			if (dropzone.value) {
				dropzone.value.removeAllFiles()
			}

			const message = response.message || response

			swalNotification(message, false)
		}

		const vremovedfile = (files, response, xhr) => {
			swalNotification("Se ha cancelado la carga")
		}

		return {
			// REFS
			refButton,
			refModal,
			modal,
			files,
			documents,
			canUpload,
			canWatch,
			canDestroy,

			// DROPZONE
			dropzoneOptions,
			dropzoneHeaders,
			dropzone,
			vsending,
			vsuccess,
			verror,
			vremovedfile,

			// ACTIONS
			getFiles,
			deleteFile,
			reset
		}
	}
}
</script>

<style lang="scss" scoped>
.description-modal {
	font-size: 1.2rem;
	font-weight: bold;
}

.content-modal {
	font-size: 0.85rem;
}
</style>

<style>
.vue-dropzone {
	border-radius: 15px !important;
	border-style: dotted !important;
	border: 2px solid #3b4253 !important;
}

.dropzone {
	background: transparent !important;
}

.dropzone .dz-preview .dz-image {
	border-radius: 10px !important;
}

.dropzone .dz-preview.dz-image-preview {
	background: transparent !important;
}

.vue-dropzone > .dz-preview .dz-details {
	background: transparent !important;
}

.vue-dropzone > .dz-preview .dz-remove {
	margin-left: 22px !important;
}

.ql-container {
	min-height: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>